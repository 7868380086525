import { Box, Drawer as MantineDrawer } from '@mantine/core';
import check from 'check-types';
import { memo, useEffect } from 'react';

interface DrawerProps {
  children?: React.ReactNode;
  onOpen?: () => void;
  transitionDuration?: number;
  title?: string | React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  withCloseButton?: boolean;
}

const Drawer = ({
  children,
  onOpen,
  transitionDuration = 250,
  title,
  isOpen,
  onClose,
  withCloseButton
}: DrawerProps) => {
  useEffect(() => {
    if (isOpen && check.function(onOpen)) {
      setTimeout(() => {
        onOpen?.();
      }, transitionDuration);
    }
  }, [isOpen]);

  return (
    <>
      <MantineDrawer
        closeOnClickOutside
        closeOnEscape
        opened={isOpen}
        overlayProps={{
          blur: 3,
          opacity: 0.55
        }}
        padding='sm'
        size={520}
        styles={{
          title: {
            width: '100%'
          },
          inner: {
            left: 65
          },
          content: {
            background: '#F9F9FB',
            overflowY: 'hidden'
          },
          header: {
            background: '#F9F9FB'
          }
        }}
        title={
          <Box className='position-relative w-100' px='xs'>
            <Box>{title}</Box>
            <Box
              className='position-absolute'
              style={{
                top: 0,
                right: 0
              }}
            ></Box>
          </Box>
        }
        transitionProps={{
          duration: transitionDuration
        }}
        withCloseButton={withCloseButton}
        withinPortal={false}
        onClose={onClose}
      >
        {children}
      </MantineDrawer>
    </>
  );
};

export default memo(Drawer);
