import { Accordion, Stack } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { IconChevronRight } from '@tabler/icons-react';

import classes from './project-menu-accordion.module.css';

import SkeletonLoader from '@/core/components/atoms/skeleton-loader/skeleton-loader';
import { Project } from '@/core/types/projects.types';

import ProjectMenuRows from '../project-menu-rows/project-menu-rows';

const HEADER_SIZE = 220;

export interface ProjectMenuAccordionProps {
  bookmarkedProjects: Project[];
  isLoading?: boolean;
  projects: Project[];
  expandProject: (id: string) => void;
  expandedRows: string[];
  isSortingOrFiltering: boolean;
  isTrainInfMenu: boolean;
  onFetchNextBookmarkedPage: () => void;
  onFetchNextPage: () => void;
}

const ProjectMenuAccordion = ({
  isLoading,
  bookmarkedProjects,
  projects,
  expandProject,
  expandedRows,
  isSortingOrFiltering,
  isTrainInfMenu,
  onFetchNextBookmarkedPage,
  onFetchNextPage
}: ProjectMenuAccordionProps) => {
  const { ref, height } = useElementSize();

  const hasNoBookmarkedProjects = bookmarkedProjects?.length === 0;

  const projectHeight = isTrainInfMenu ? 75 : 65;

  const bookmarkedAccordionHeight =
    bookmarkedProjects?.length * projectHeight > 200
      ? 200
      : bookmarkedProjects?.length * projectHeight;

  const tabsSize = isTrainInfMenu ? 90 : 0;

  // Render
  if (isLoading) {
    return (
      <Stack gap='md'>
        <SkeletonLoader height={60} length={10} width={500} />
      </Stack>
    );
  }

  if (hasNoBookmarkedProjects) {
    return (
      <ProjectMenuRows
        data={projects ?? []}
        expandedRows={expandedRows as string[]}
        expandProject={expandProject}
        height={`calc(100vh - ${HEADER_SIZE + tabsSize - 50}px)`}
        key={isLoading ? 'loading' : 'loaded'}
        scrollToActiveRow={!isSortingOrFiltering}
        onFetchNextPage={onFetchNextPage}
      />
    );
  }

  return (
    <Accordion
      multiple
      chevron={<IconChevronRight size={16} />}
      chevronPosition='left'
      classNames={classes}
      defaultValue={['bookmarked', 'all']}
    >
      <Accordion.Item
        data-testid='bookmarked-projects'
        mb={10}
        style={{
          borderBottom: 'none'
        }}
        value='bookmarked'
      >
        <Accordion.Control>Bookmarked</Accordion.Control>
        <Accordion.Panel ref={ref}>
          <ProjectMenuRows
            data={bookmarkedProjects ?? []}
            expandedRows={expandedRows as string[]}
            expandProject={expandProject}
            height={bookmarkedAccordionHeight}
            scrollToActiveRow={false}
            onFetchNextPage={onFetchNextBookmarkedPage}
          />
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item
        style={{
          borderBottom: 'none'
        }}
        value='all'
      >
        <Accordion.Control>All</Accordion.Control>
        <Accordion.Panel>
          <ProjectMenuRows
            data={projects ?? []}
            expandedRows={expandedRows as string[]}
            expandProject={expandProject}
            height={`calc(100vh - ${height + HEADER_SIZE + tabsSize}px)`}
            scrollToActiveRow={!isSortingOrFiltering}
            onFetchNextPage={onFetchNextPage}
          />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default ProjectMenuAccordion;
