import { OverlayProps, Text, TextProps } from '@mantine/core';
import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import React, { PropsWithChildren, useCallback } from 'react';

import { usePrefetch } from '@/core/hooks/use-prefetch/use-prefetch';
import { Permission } from '@/core/types/user-permissions.types';

import { PermissionOverlay } from '../permission-overlay/permission-overlay';

interface LinkSpanProps extends LinkProps {
  textProps?: TextProps;
  overlayProps?: OverlayProps;
  permissions?: Permission;
  onClick?: () => void;
}
export function LinkSpan({
  children,
  permissions,
  textProps,
  overlayProps,
  onClick,
  ...props
}: PropsWithChildren<LinkSpanProps>) {
  // Router
  const router = useRouter();

  // Computed
  const isDisabled = permissions && !permissions.allowed;

  // Prefetch Hook
  usePrefetch({
    isDisabled: Boolean(isDisabled),
    href: props.href
  });

  // Handlers
  const navigate = useCallback(async () => {
    onClick?.();

    await router.push(props.href, props?.as, {
      shallow: props?.shallow,
      scroll: props?.scroll
    });
  }, []);

  // Render
  if (isDisabled) {
    return (
      <PermissionOverlay
        allowed={permissions.allowed}
        display='inline'
        message={permissions.message}
        overlayProps={overlayProps}
      >
        <Text c='gray.6' component='span' fw={600} {...textProps}>
          {children}
        </Text>
      </PermissionOverlay>
    );
  }

  return (
    <Text
      c='brand.6'
      component='span'
      fw={600}
      role='link'
      style={{ cursor: 'pointer' }}
      {...textProps}
      onClick={navigate}
    >
      {children}
    </Text>
  );
}
